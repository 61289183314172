
























































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import moment from "moment";
import {
  StatsCard,
  ChartCard,
  // ProductCard,
  AnimatedNumber,
  GlobalSalesCard
  // GlobalSalesTable
} from "@/components";
import { http } from "@/resources";
import { Store, Scene } from "../../resources/interfaces";
import DoughnutChart from "@/components/Charts/DoughnutChart";

@Component({
  components: {
    StatsCard,
    ChartCard,
    AnimatedNumber,
    // ProductCard,
    GlobalSalesCard,
    // GlobalSalesTable,
    DoughnutChart
  }
})
export default class BossBoard extends Vue {
  store: Store | null | "none" = null;
  date = moment().format("YYYY-MM-DD");
  dateEnd: null | string = null;
  today = moment().format("YYYY-MM-DD");
  queryStartTimeout: null | number = null;
  amountType: "revenueByScenesInternalExclusive" | "salesByScenes" =
    "salesByScenes";
  stats: {
    customerCount: number;
    customersByStore: Record<
      string,
      { adultsCount: number; kidsCount: number }
    >;
    bookingsCountByType: Partial<Record<Scene, number>>;
    bookingsCountByStore: Record<string, Partial<Record<Scene, number>>>;
    assets: number;
    sales: number;
    revenueByScenesInternalExclusive: Record<string, number>;
    dailySalesAmount: { amount: number; day: 1 | 2 | 3 | 4 | 5 | 6 | 7 }[];
    salesByScenes: Record<string, number>;
    salesByClient: Record<string, number>;
    assetsByScenes: Record<string, number>;
    salesByStores: Record<string, number>;
    assetsByStoresByScenes: Record<string, Record<string, number>>;
    salesByStoresByScenes: {
      assets: number;
      saleStore: string;
      scene: Scene;
    }[];
    revenueByStoresByScenes: {
      revenue: number;
      store: string;
      scene: Scene;
    }[];
    customersByType: Record<string, { adultsCount: number; kidsCount: number }>;
  } = {
    customerCount: 0,
    customersByStore: {},
    bookingsCountByType: {},
    bookingsCountByStore: {},
    assets: 0,
    sales: 0,
    revenueByScenesInternalExclusive: {},
    dailySalesAmount: [],
    salesByScenes: {},
    salesByClient: {},
    assetsByScenes: {},
    salesByStores: {},
    assetsByStoresByScenes: {},
    salesByStoresByScenes: [],
    revenueByStoresByScenes: [],
    customersByType: {}
  };
  kpisByStore: {
    id: string;
    name: string;
    pers: number;
    kids: number;
    sales: number;
    parties: number;
    cardAssetsPerKid: number;
    foodAssetsPerPrs: number;
    labGoodAssetsPerKid: number;
  }[] = [];
  weekdayMapping = {
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
    7: "日"
  };
  loading = false;
  kpiByStoreSorting = "desc";
  kpiByStoreSortBy = "sales";

  @Watch("kpiByStoreSorting") onKpiByStoreSortChange() {
    this.$nextTick().then(() => {
      this.kpiByStoreSorting = "desc";
    });
  }

  kpiByStoreSort(input: any) {
    const by = this.kpiByStoreSortBy;
    return input.sort((a: any, b: any) => (a[by] < b[by] ? 1 : -1));
  }

  addDate(add: number) {
    this.date = moment(this.date).add(add, "days").format("YYYY-MM-DD");
    if (this.dateEnd) {
      this.dateEnd = moment(this.dateEnd).add(add, "days").format("YYYY-MM-DD");
    }
  }

  async downloadReport() {
    this.loading = true;
    const { downloadUrl } = (
      await http.get(
        "/holder-report/" +
          (this.date + (this.dateEnd ? "~" + this.dateEnd : ""))
      )
    ).data;
    this.loading = false;
    window.open(downloadUrl);
  }

  selectStore(store: Store | null) {
    this.store = store;
  }

  getStoreSales(storeId: string, scenes: Scene[]) {
    return this.stats.salesByStoresByScenes
      .filter(i => i.saleStore === storeId && scenes.includes(i.scene))
      .reduce((s, i) => s + i.assets, 0);
  }

  getStoreRevenue(storeId: string, scenes: Scene[]) {
    return this.stats.revenueByStoresByScenes
      .filter(i => i.store === storeId && scenes.includes(i.scene))
      .reduce((s, i) => s + i.revenue, 0);
  }

  updateStats() {
    this.loading = true;
    if (this.queryStartTimeout) {
      window.clearTimeout(this.queryStartTimeout);
    }
    let url = `stats`;
    if (this.date) {
      url += `/${this.date}`;
    }
    if (this.dateEnd) {
      url += `/${this.dateEnd}`;
    }
    url += "?taxExclusive=true";
    if (this.store) {
      url += `&store=${this.store === "none" ? "none" : this.store.id}`;
    }
    this.queryStartTimeout = window.setTimeout(async () => {
      this.stats = (await http.get(url)).data;
      this.queryStartTimeout = null;
      this.loading = false;
      this.kpisByStore = this.$stores
        .filter(s => s.order >= 0)
        .map(store => ({
          id: store.id,
          name: store.name,
          pers:
            this.stats.customersByStore[store.id]?.adultsCount +
            this.stats.customersByStore[store.id]?.kidsCount,
          kids: this.stats.customersByStore[store.id]?.kidsCount,
          sales: this.stats.salesByStores[store.id],
          cardAssetsPerKid:
            this.getStoreSales(store.id, [
              Scene.CARD,
              Scene.PERIOD,
              Scene.BALANCE
            ]) / this.stats.customersByStore[store.id]?.kidsCount,
          foodAssetsPerPrs:
            this.getStoreRevenue(store.id, [Scene.FOOD]) /
            (this.stats.customersByStore[store.id]?.kidsCount +
              this.stats.customersByStore[store.id]?.adultsCount),
          labGoodAssetsPerKid:
            this.getStoreSales(store.id, [Scene.RETAIL, Scene.EVENT]) /
            this.stats.customersByStore[store.id]?.kidsCount,
          parties: this.stats.bookingsCountByStore[store.id]?.party || 0
        }))
        .sort((a, b) => (a.sales > b.sales ? -1 : 1));
    }, 100);
  }

  get rangeText() {
    if (!this.dateEnd || this.dateEnd === this.date) {
      return "当日";
    } else {
      return "区间";
    }
  }

  get adultsCount() {
    return Object.values(this.stats.customersByType).reduce(
      (count, type) => count + type.adultsCount,
      0
    );
  }

  get kidsCount() {
    return Object.values(this.stats.customersByType).reduce(
      (count, type) => count + type.kidsCount,
      0
    );
  }

  get statsAmountByScene() {
    return this.stats[this.amountType];
  }

  get playSalesAmount() {
    return (
      (this.statsAmountByScene.play || 0) +
      (this.statsAmountByScene.card || 0) +
      (this.statsAmountByScene.balance || 0) +
      (this.statsAmountByScene.period || 0)
    );
  }

  get salesByClientDesc() {
    return Object.keys(this.stats.salesByClient)
      .filter(c => c)
      .map(clientName => ({
        clientName,
        amount: this.stats.salesByClient[clientName]
      }))
      .sort((a, b) => (a.amount < b.amount ? 1 : -1))
      .map(i => `${i.clientName}:${i.amount.toFixed()}`)
      .join("，");
  }

  get dailySalesChart() {
    const values = this.stats.dailySalesAmount.map(d => d.amount);
    const labels = this.stats.dailySalesAmount.map(
      d => this.weekdayMapping[d.day]
    );
    const high = Math.max(...values) * 1.05;
    const low = Math.min(...values) * 0.95;
    return {
      data: {
        labels: labels,
        series: [values]
      },
      options: {
        axisX: {
          showGrid: false
        },
        axisY: {
          labelInterpolationFnc: function (value: number) {
            return value / 10000 + "万";
          }
        },
        low,
        high,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 10
        }
      }
    };
  }

  get sceneFlowPieChart() {
    const values = Object.values(this.statsAmountByScene).map(
      v => +v.toFixed()
    );
    const labels = Object.keys(this.statsAmountByScene).map(
      key => this.$sceneNames[key]
    );
    const sceneColor = {
      play: "#C2ACE5",
      food: "#FFDEC0",
      party: "#FFCCE9",
      mall: "#A8FC90",
      event: "#FFDD7D",
      balance: "#D1D1D1",
      card: "#97F2E1",
      period: "#93C4F9"
    };
    return {
      data: {
        labels: labels,
        datasets: [
          {
            data: values,
            backgroundColor: Object.keys(this.statsAmountByScene).map(
              // @ts-ignore
              key => sceneColor[key]
            )
          }
        ]
      }
    };
  }

  mounted() {
    this.updateStats();
  }

  @Watch("date") onDateUpdate(val: string) {
    const today = moment().format("YYYY-MM-DD");
    if (!val) {
      this.date = today;
    }
    this.updateStats();
    this.date !== today
      ? window.localStorage.setItem("dashboard.date", val)
      : window.localStorage.removeItem("dashboard.date");
    // console.log(this.stats);
  }

  @Watch("dateEnd") onDateEndUpdate(val: string) {
    this.updateStats();
    val
      ? window.localStorage.setItem("dashboard.dateEnd", val)
      : window.localStorage.removeItem("dashboard.dateEnd");
  }

  @Watch("store") onStoreUpdate() {
    this.updateStats();
  }

  created() {
    const date = window.localStorage.getItem("dashboard.date");
    const dateEnd = window.localStorage.getItem("dashboard.dateEnd");
    if (date) this.date = date;
    if (dateEnd) this.dateEnd = dateEnd;
  }
}
