














































































































































































































































































































































































































































































import Vue from "vue";
import { Component, Watch } from "vue-property-decorator";
import moment from "moment";
import {
  StatsCard,
  ChartCard,
  // ProductCard,
  AnimatedNumber,
  GlobalSalesCard
  // GlobalSalesTable
} from "@/components";
import { http } from "@/resources";
import { Store, Scene } from "../../resources/interfaces";

@Component({
  components: {
    StatsCard,
    ChartCard,
    AnimatedNumber,
    // ProductCard,
    GlobalSalesCard
    // GlobalSalesTable,
  }
})
export default class Dashboard extends Vue {
  store: Store | null = null;
  date = moment().format("YYYY-MM-DD");
  dateEnd: null | string = null;
  today = moment().format("YYYY-MM-DD");
  queryStartTimeout: null | number = null;
  stats: {
    bookingsCountByType: Partial<Record<Scene, number>>;
    customersByType: Record<
      "card" | "coupon" | "guest" | "balance" | "contract" | "other",
      { adultsCount: number; kidsCount: number }
    >;
    customers: number;
    kidsCount: number;
    assetsByGateways: Record<string, number>;
    assetsByScenes: Record<string, number>;
    salesByStoresByScenes: {
      assets: number;
      saleStore: string;
      scene: Scene;
    }[];
    revenueByScenes: Record<string, number>;
    revenueByScenesInternalExclusive: Record<string, number>;
    revenue: number;
    couponsCount: {
      times: number;
      name: string;
      amount: number;
    }[];
    cardsCount: {
      adultsCount: number;
      times: number;
      name: string;
      isContract: boolean;
      amount: number;
      type: string;
    }[];
    balanceCount: {};
    cardsSellCount: {
      name: number;
      count: number;
      amount: number;
      times?: number;
      balance?: number;
    }[];
    dailyCustomers: {
      adultsCount: number;
      kidsCount: number;
      day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    }[];
    dailySalesAmount: { amount: number; day: 1 | 2 | 3 | 4 | 5 | 6 | 7 }[];
    dailyRevenue: {
      revenue: number;
      day: 1 | 2 | 3 | 4 | 5 | 6 | 7;
    }[];
  } = {
    bookingsCountByType: {},
    customersByType: {
      card: { adultsCount: 0, kidsCount: 0 },
      coupon: { adultsCount: 0, kidsCount: 0 },
      guest: { adultsCount: 0, kidsCount: 0 },
      balance: { adultsCount: 0, kidsCount: 0 },
      contract: { adultsCount: 0, kidsCount: 0 },
      other: { adultsCount: 0, kidsCount: 0 }
    },
    customers: 0,
    kidsCount: 0,
    assetsByGateways: {},
    assetsByScenes: {},
    salesByStoresByScenes: [],
    revenueByScenes: {},
    revenueByScenesInternalExclusive: {},
    revenue: 0,
    couponsCount: [],
    cardsSellCount: [],
    cardsCount: [],
    balanceCount: {},
    dailyCustomers: [],
    dailySalesAmount: [],
    dailyRevenue: []
  };
  weekdayMapping = {
    1: "一",
    2: "二",
    3: "三",
    4: "四",
    5: "五",
    6: "六",
    7: "日"
  };
  loading = false;

  get startAvailableDay() {
    if (this.$user.can("PAYMENT_ALL_DATE")) {
      return NaN;
    } else if (this.$user.can("PAYMENT_LAST_MONTH")) {
      return moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
    } else if (this.$user.can("PAYMENT_LAST_WEEK")) {
      return moment().subtract(1, "week").startOf("week").format("YYYY-MM-DD");
    }
    return moment().format("YYYY-MM-DD");
  }

  addDate(add: number) {
    this.date = moment(this.date).add(add, "days").format("YYYY-MM-DD");
    if (this.dateEnd) {
      this.dateEnd = moment(this.dateEnd).add(add, "days").format("YYYY-MM-DD");
    }
  }

  async downloadDailyReport() {
    this.loading = true;
    const { downloadUrl } = (
      await http.get(
        "/daily-report/" +
          (this.date + (this.dateEnd ? "~" + this.dateEnd : ""))
      )
    ).data;
    this.loading = false;
    window.open(downloadUrl);
  }

  downloadCardsSellCount() {
    window.location.href =
      process.env.VUE_APP_API_BASE +
      "/stats/cards-sell-count/" +
      (this.date + (this.dateEnd ? "~" + this.dateEnd : "")) +
      "?token=" +
      window.localStorage.token;
  }

  async downloadCardsCouponsCount() {
    this.loading = true;
    const { downloadUrl } = (
      await http.get(
        "/stats/cards-coupons-count/" +
          (this.date + (this.dateEnd ? "~" + this.dateEnd : ""))
      )
    ).data;
    this.loading = false;
    window.open(downloadUrl);
  }

  selectStore(store: Store | null) {
    this.store = store;
  }

  disabledDates(date: Date) {
    if (this.$user.can("PAYMENT_ALL_DATE")) {
      return false;
    } else if (this.$user.can("PAYMENT_LAST_MONTH")) {
      const start = moment().subtract(1, "month").startOf("month").valueOf();
      return date.valueOf() < start;
    } else if (this.$user.can("PAYMENT_LAST_WEEK")) {
      const start = moment().startOf("day").valueOf();
      return date.valueOf() < start;
    } else if (this.$user.can("PAYMENT")) {
      return moment()
        .subtract(1, "month")
        .startOf("month")
        .format("YYYY-MM-DD");
    }
  }

  updateStats() {
    this.loading = true;
    if (this.queryStartTimeout) {
      window.clearTimeout(this.queryStartTimeout);
    }
    let url = `stats`;
    if (this.date) {
      url += `/${this.date}`;
    }
    if (this.dateEnd) {
      url += `/${this.dateEnd}`;
    }

    const params: Record<string, string> = {};
    if (this.store) {
      params.store = this.store.id;
    }
    this.queryStartTimeout = window.setTimeout(async () => {
      this.stats = (await http.get(url, { params })).data;
      this.queryStartTimeout = null;
      this.loading = false;
    }, 100);
  }

  get rangeText() {
    if (!this.dateEnd || this.dateEnd === this.date) {
      return "当日";
    } else {
      return "区间";
    }
  }

  get assetsByGatewayNames() {
    return Object.keys(this.stats.assetsByGateways)
      .map(gateway => ({
        name: this.$gatewayNames[gateway],
        amount: this.stats.assetsByGateways[gateway]
      }))
      .filter(p => p.amount);
  }

  get assetsBySceneNames() {
    return Object.keys(this.stats.assetsByScenes)
      .map(scene => ({
        name: this.$sceneNames[scene],
        amount: this.stats.assetsByScenes[scene]
      }))
      .filter(p => p.amount);
  }

  get revenueBySceneNames() {
    return Object.keys(this.stats.revenueByScenesInternalExclusive)
      .map(scene => ({
        name: this.$sceneNames[scene],
        amount: this.stats.revenueByScenesInternalExclusive[scene]
      }))
      .filter(p => p.amount);
  }

  get cardSales() {
    return this.stats.salesByStoresByScenes
      .filter(i => [Scene.CARD, Scene.PERIOD, Scene.BALANCE].includes(i.scene))
      .reduce((s, i) => s + i.assets, 0);
  }

  get dailyCustomersChart() {
    const values = this.stats.dailyCustomers.map(
      d => d.adultsCount + d.kidsCount
    );
    const labels = this.stats.dailyCustomers.map(
      d => this.weekdayMapping[d.day]
    );
    const high = Math.max(...values) * 1.05;
    const low = Math.min(...values) * 0.95;
    return {
      data: {
        labels: labels,
        series: [values]
      },
      options: {
        axisX: {
          showGrid: false
        },
        low,
        high,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 0
        }
      }
    };
  }

  get dailySalesChart() {
    const values = this.stats.dailySalesAmount.map(d => d.amount);
    const labels = this.stats.dailySalesAmount.map(
      d => this.weekdayMapping[d.day]
    );
    const high = Math.max(...values) * 1.05;
    const low = Math.min(...values) * 0.95;
    return {
      data: {
        labels: labels,
        series: [values]
      },
      options: {
        axisX: {
          showGrid: false
        },
        axisY: {
          labelInterpolationFnc: function (value: number) {
            return value / 10000 + "万";
          }
        },
        low,
        high,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 10
        }
      }
    };
  }

  get dailyRevenueChart() {
    const values = this.stats.dailyRevenue.map(d => d.revenue);
    const labels = this.stats.dailyRevenue.map(d => this.weekdayMapping[d.day]);
    const high = Math.max(...values) * 1.05;
    const low = Math.min(...values) * 0.95;
    return {
      data: {
        labels: labels,
        series: [values]
      },
      options: {
        axisX: {
          showGrid: false
        },
        axisY: {
          labelInterpolationFnc: function (value: number) {
            return value / 10000 + "万";
          }
        },
        low,
        high,
        chartPadding: {
          top: 0,
          right: 5,
          bottom: 0,
          left: 10
        }
      }
    };
  }

  get cardsSellCount() {
    return this.stats.cardsSellCount.sort((a, b) => b.count - a.count);
  }

  get couponsCount() {
    return this.stats.couponsCount.sort((a, b) => b.times - a.times);
  }

  get cardsCount() {
    return this.stats.cardsCount.sort((a, b) => b.times - a.times);
  }

  get cardBalanceByTypes() {
    return [
      ...this.stats.cardsCount.filter(
        i => !i.isContract && i.type !== "coupon"
      ),
      this.stats.balanceCount
    ];
  }

  created() {
    // console.log("[DASHBOARD] Created.");
    if (this.$route.query.date) {
      this.date = this.$route.query.date as string;
    } else {
      const date = window.localStorage.getItem("dashboard.date");
      const dateEnd = window.localStorage.getItem("dashboard.dateEnd");
      if (date) this.date = date;
      if (dateEnd) this.dateEnd = dateEnd;
    }
  }

  activated() {
    this.updateStats();
  }

  @Watch("date") onDateUpdate(val: string) {
    const today = moment().format("YYYY-MM-DD");
    if (!val) {
      this.date = today;
    }
    this.updateStats();
    this.date !== today
      ? window.localStorage.setItem("dashboard.date", val)
      : window.localStorage.removeItem("dashboard.date");
    // console.log(this.stats);
  }

  @Watch("dateEnd") onDateEndUpdate(val: string) {
    this.updateStats();
    window.localStorage.setItem("dashboard.dateEnd", val);
    val
      ? window.localStorage.setItem("dashboard.dateEnd", val)
      : window.localStorage.removeItem("dashboard.dateEnd");
  }

  @Watch("store") onStoreUpdate() {
    this.updateStats();
  }
}
