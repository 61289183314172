













import Vue from "vue";
import { Component } from "vue-property-decorator";
import { StaffResource } from "@/resources";
import { Staff } from "@/resources/interfaces";

@Component
export default class MyBoard extends Vue {
  staff: Staff | null = null;

  async created() {
    const [staff] = await StaffResource.query();
    if (staff) {
      this.staff = staff;
    }
  }
}
